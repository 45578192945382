import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

const LinkAnchorStyles = css`
  color: ${props => props.theme.primaryColor};
  cursor: pointer;
  position: relative;
  font-weight: 600;

  &:after {
    position: absolute;
    z-index: -1;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 100%;
    height: 0px;
    background-color: ${props => props.theme.highlightColor};
    transition: all 0.1s;
  }

  &:hover {
    color: white;
    &:after {
      height: 120%;
      width: 120%;
    }
  }
`;

export const StyledLink = styled(Link)`
  ${LinkAnchorStyles}
`;
export const StyledAnchor = styled.a`
  ${LinkAnchorStyles}
`;
