export default {
  light: {
    name: 'light',
    primaryColor: '#ae33df',
    primaryBackgroundColor: 'linear-gradient(#fff, #dadada)',
    secondaryBackgroundColor: 'linear-gradient(#dadada, #efefef)',
    tertiaryBackgroundColor: '#ddd',
    highlightColor: '#d64085',
    textColor: '#222',
    titleColor: '#c60055',
    techColor: '#4b507d',
    shadowColor: '#b0cade'
  },
  dark: {
    name: 'dark',
    primaryColor: '#ae66df',
    primaryBackgroundColor: 'linear-gradient(#404040, #050505)',
    secondaryBackgroundColor: 'linear-gradient(#525252, #303030)',
    tertiaryBackgroundColor: '#101010',
    highlightColor: '#ee4479',
    textColor: '#e3d9e5',
    titleColor: '#ed64a9',
    techColor: '#ccb',
    shadowColor: '#314a60'
  }
};
