import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import light from '../../static/light.svg';
import dark from '../../static/dark.svg';

const HeaderContainer = styled.header`
  background-color: ${props => props.theme.tertiaryBackgroundColor};
  color: ${props => props.theme.textColor};
  padding: 0.8rem 0 1rem;
  position: fixed;
  height: 65px;
  width: 100vw;
  z-index: 1;
  font-size: 0.9rem;

  @media screen and (min-width: 768px) {
    font-size: 1rem;
  }
`;

const HeaderList = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0 auto;
  width: 80vw;
  justify-content: space-between;
  min-width: 280px;
  max-width: 400px;
`;

const activeClassName = 'active';
const HeaderLink = styled(Link).attrs({ activeClassName })`
  font-weight: 600;
  text-decoration: none;
  position: relative;
  margin-bottom: 0 auto;
  padding-bottom: 5px;
  color: inherit;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 0;
    bottom: -3px;
    background: ${props => props.theme.highlightColor};
    height: 3px;
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  &:hover:after {
    left: 0;
    right: auto;
    width: 100%;
  }

  &.${activeClassName} {
    border-bottom: 3px solid ${props => props.theme.primaryColor};
  }
`;

const ThemeToggler = styled.button`
  border: 0;
  cursor: pointer;
  background: #4b507d;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  z-index: 2;

  &:focus {
    outline: none;
  }

  &::before {
    content: '';
    position: absolute;
    width 100%;
    height: 100%;
    background: ${props => props.theme.primaryColor};
    left: -110%;
  }
  &:hover::before {
    animation: slide 0.7s 1;
    left: 0%;
  }
  @keyframes slide {
    0%{
      left: -110%;
    }
    50%{
      left: 70%;
    }
    100%{
      left: 0%;
    }
  }

  &:hover img {
    transform: scale(1.3);
    color: #f1f1f1;
  }

`;

const ThemeIcon = styled.img`
  height: 22px;
  width: 22px;
  padding-top: 5px;
  transition: all 0.3s ease;
  position: relative;
  z-index: 3;

  @media screen and (min-width: 768px) {
    height: 28px;
    width: 28px;
  }
`;

const Header = ({ toggleTheme, darkTheme }) => {
  return (
    <HeaderContainer>
      <nav>
        <HeaderList>
          <li>
            <HeaderLink to="/">Home</HeaderLink>
          </li>
          <li>
            <HeaderLink to="/projects">Projects</HeaderLink>
          </li>
          <li>
            <HeaderLink to="/blog">Blog</HeaderLink>
          </li>
          <li>
            <HeaderLink to="/contact">Contact</HeaderLink>
          </li>
          <li>
            <ThemeToggler onClick={toggleTheme}>
              {darkTheme ? (
                <span>
                  <ThemeIcon src={light} alt="Light Theme" />
                </span>
              ) : (
                <span>
                  <ThemeIcon src={dark} alt="Dark Theme" />
                </span>
              )}
            </ThemeToggler>
          </li>
        </HeaderList>
      </nav>
    </HeaderContainer>
  );
};

export default Header;
