import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { toggleTheme } from '../styles/redux-theme';
import Header from './Header';
import Footer from './Footer';

import '../styles/index.scss';
import themes from '../styles/themes';
import styled, { ThemeProvider } from 'styled-components';

const LayoutContainer = styled.div`
  background-image: ${props => props.theme.primaryBackgroundColor};
  color: ${props => props.theme.textColor};
  position: relative;
  display: flex;
  top: 65px;
  z-index: 0;
  min-width: 20rem;
  min-height: 100vh;
  padding-bottom: 2.5rem;
  transition: all 0.3s ease;
`;

const LayoutMain = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1.5rem;
  padding-bottom: 0;

  @media screen and (min-width: 768px) {
    max-width: 1000px;
    padding: 3rem 4rem;
  }
`;

const Layout = ({ children, darkTheme, toggleTheme }) => {
  const mode = darkTheme ? 'dark' : 'light';

  return (
    <ThemeProvider theme={themes[mode]}>
      <>
        <Helmet>
          <title>David Kurniawan</title>
          <meta name="description" content="David Kurniawan" />
        </Helmet>
        <Header toggleTheme={toggleTheme} darkTheme={darkTheme} />
        <LayoutContainer>
          <LayoutMain>{children}</LayoutMain>
          <Footer />
        </LayoutContainer>
      </>
    </ThemeProvider>
  );
};

export default connect(
  ({ theme: darkTheme }) => darkTheme,
  { toggleTheme }
)(Layout);
