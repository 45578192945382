import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { StyledLink } from '../components/styledLinkAnchor';

export default () => {
  return (
    <Layout>
      <WelcomeName>Hi, I'm David Kurniawan.</WelcomeName>
      <p>
        I'm a Full Stack Developer with skills and professional experience in
        developing cloud-based web applications. I'm an excellent problem
        solver, it is exciting and satisfying to see how I can help others with
        my skills and experience.
      </p>
      <p>Technologies and tools that I regularly use:</p>
      <ul>
        <li>ReactJS</li>
        <li>Redux</li>
        <li>JavaScript</li>
        <li>.NET Core</li>
        <li>SQL Server</li>
        <li>Azure Cloud</li>
        <li>Azure DevOps</li>
        <li>Slack</li>
        <li>Trello</li>
      </ul>
      <p>
        If I am not in front of my computer, I usually enjoy doing outdoor
        cycling, hitting the gym, cooking hearty meals or baking some cookies.
        It is important to have a balanced and healthy life in order to stay
        refreshed, motivated and driven for the challenges ahead.
      </p>
      <p>
        <StyledLink to='/contact'>Reach me out</StyledLink>
      </p>
    </Layout>
  );
};

const WelcomeName = styled.h3`
  color: ${(props) => props.theme.titleColor};
`;
